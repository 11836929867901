import React from "react";
/**
 * A reusable modal dialog component
 * Designed based off TCs v3 UI
 * BYO buttons and functionality through children
 */
export var Dialog = function (_a) {
    var children = _a.children, open = _a.open, title = _a.title, onClose = _a.onClose;
    if (!open)
        return null;
    return (
    // Full screen overlay that centers dialog in viewport
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background overlay that closes the dialog on click */}
      <div className="absolute inset-0 bg-black opacity-50" aria-hidden="true" onClick={onClose} data-testid="dialog-backdrop"/>
      {/* Main modal w/ white body */}
      <div role="dialog" aria-modal="true" className="relative rounded-xl bg-white px-4 shadow-xl">
        {/* Header section w/ title */}
        <div className="block pt-5 text-xl font-bold">{title}</div>
        {/* Dialog content w/ padding */}
        <div className="py-2">{children}</div>
      </div>
    </div>);
};
